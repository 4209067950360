// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-art-js": () => import("./../../../src/components/art.js" /* webpackChunkName: "component---src-components-art-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-isle-of-olympia-js": () => import("./../../../src/pages/the-isle-of-olympia.js" /* webpackChunkName: "component---src-pages-the-isle-of-olympia-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-web-js": () => import("./../../../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */)
}

